import { useAppReadyState } from '@/atoms/app-ready';
import Button from '../Button';
import Link from '../Link';
import { useState } from 'react';
import Responsive from '../Responsive';
import { fromCDN } from '@/cdn';
import dynamic from 'next/dynamic';

const LottieAnimation = dynamic(() => import('@/components/shared/LottieAnimation'), { ssr: false });

interface Props {
    category: {
        name: string;
        slug: string;
        lottie: string;
    };
}

const MainLottieCard = ({ category }: Props) => {
    const appReady = useAppReadyState();
    const [hovered, setHovered] = useState(false);

    return (
        <li className="parting-list__item">
            <Link
                href={`/${category.slug}`}
                className="parting-item"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onTouchStart={() => setHovered(true)}
                onTouchEnd={() => setHovered(false)}
            >
                <div className="parting-item__img-container">
                    <Responsive className="parting-item__img-wrapper">
                        {appReady && (
                            <LottieAnimation
                                path={fromCDN(`/lottie/${category.lottie}.json`)}
                                paused={!hovered}
                                className="catalog-landing-lottie"
                            />
                        )}
                    </Responsive>
                </div>
                <Button tag="div" geometryVariant="large" variant="default">
                    {category.name}
                </Button>
            </Link>
        </li>
    );
};

export default MainLottieCard;
