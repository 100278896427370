import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { CommonPageProps, NonUndefined } from '@/types';
import DefaultLayout from '@/components/layout/DefaultLayout';
import { getCommonPageProps } from '@/api/common';
import { getPage } from '@/api/page';
import MainLottieCard from '@/components/shared/MainLottieCard';

const ROTOR_WATCH_SLUG = 'rotormine';
const JEWELRYMINE_SLUG = 'jewelrymine';

const categories = [
    {
        name: 'часы',
        slug: ROTOR_WATCH_SLUG,
        lottie: 'watch',
    },
    {
        name: 'украшения',
        slug: JEWELRYMINE_SLUG,
        lottie: 'decoration',
    },
] as const;

const IndexPage = ({ h1 }: InferGetStaticPropsType<typeof getStaticProps>) => {
    return (
        <DefaultLayout className="parting-page-content">
            <h1 className="visually-hidden">{h1}</h1>
            <div className="parting-page-hero">
                <div
                    className="text-l parting-page-text"
                    dangerouslySetInnerHTML={{ __html: 'Выберите, то&nbsp;с&nbsp;чего&nbsp;бы вы&nbsp;хотели начать' }}
                />
                <ul className="list-unstyled parting-list">
                    {categories.map((category) => {
                        return <MainLottieCard key={category.slug} category={category} />;
                    })}
                </ul>
            </div>
        </DefaultLayout>
    );
};

export default IndexPage;

type IndexPageProps = NonUndefined<
    CommonPageProps & {
        h1: string;
    }
>;

export const getStaticProps: GetStaticProps<IndexPageProps> = async () => {
    const [commonPageProps, pageInfo] = await Promise.all([getCommonPageProps(), getPage('main')]);

    return {
        props: {
            ...commonPageProps,
            bodyClass: 'index-page',
            meta: {
                ...commonPageProps.meta,
                title: 'Главная',
                ...pageInfo.data?.meta,
            },
            h1: 'Rotor watch',
        } satisfies IndexPageProps,
        revalidate: 60,
    };
};
